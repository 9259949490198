import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { XCircleIcon } from "@heroicons/react/solid";
import Auth from "../assets/auth.svg";

const Login = () => {
  const { loading, error, dispatch } = useContext(AuthContext);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userName && password === "") {
      dispatch({ type: "LOGIN_FAILURE", payload: "Password is required" });
    }

    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/local`,
        {
          identifier: userName,
          password: password,
        }
      );
      //console.log(res);
      //Cookie.set("jwt", res.data.jwt);

      dispatch({ type: "LOGIN_SUCCESS", payload: res.data.user });
      localStorage.setItem("jwt", res.data.jwt);
      navigate("/dashboard");
    } catch (err) {
      dispatch({
        type: "LOGIN_FAILURE",
        payload: err.response.data.error,
      });
    }
  };

  return (
    <>
      <section className="h-screen">
        <div className="px-6 h-full text-gray-800">
          <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
            <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
              <img src={Auth} className="w-full" alt="Sample " />
            </div>
            <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
              {error && (
                <>
                  <div
                    className="bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full"
                    role="alert"
                  >
                    <XCircleIcon className="h-5 w-5 mr-2 text-red-700" />

                    <span>{error.message}</span>
                  </div>
                </>
              )}
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <input
                    id="username"
                    value={userName}
                    autoComplete="username"
                    onChange={(e) => setUserName(e.target.value)}
                    type="text"
                    required
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="User ID or Email "
                  />
                </div>

                <div className="mb-6">
                  <input
                    id="password"
                    autoComplete="password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Password"
                  />
                </div>

                <div className="text-center lg:text-left">
                  <button
                    disabled={loading}
                    type="submit"
                    className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
