// api data context

import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";

export const ApiDataContext = createContext();

export const ApiDataProvider = ({ children }) => {
  const [engData, setEngData] = useState([]);
  const [assData, setAssData] = useState([]);
  const [benData, setBenData] = useState([]);
  const [hindiData, setHindiData] = useState([]);
  const [engAllData, setEngAllData] = useState([]);
  const [assAllData, setAssAllData] = useState([]);
  const [benAllData, setBenAllData] = useState([]);
  const [hindiAllData, setHindiAllData] = useState([]);
  const [showAlert, setShowAlert] = useState(true);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertColour, setAlertColour] = useState("teal");
  const [apiData, setApiData] = useState([]);
  const [error, setError] = useState(null);

  const { user } = useContext(AuthContext);

  // function for greetings message
  const d = new Date();
  const time = d.getHours();

  const greetings =
    time < 12
      ? "Good Morning "
      : time < 16
      ? "Good Afternoon "
      : "Good Evening ";

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/users/me`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();

        setApiData(data);
        setError(null);
      } catch (err) {
        setError(err.message);
      }
    };
    getData();
  }, []);

  let name = user ? user.username : "";

  let username = apiData.username || name;

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/englishpapers?filters[uploaded_by][$eq]=${username}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { meta } = await response.json();
        const modifiedData = meta.pagination.total;
        setEngData(modifiedData);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [username]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/englishpapers`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { meta } = await response.json();
        const modifiedData = meta.pagination.total;
        setEngAllData(modifiedData);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/assamesepapers?filters[uploaded_by][$eq]=${username}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { meta } = await response.json();
        const modifiedData = meta.pagination.total;
        setAssData(modifiedData);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [username]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/assamesepapers`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { meta } = await response.json();
        const modifiedData = meta.pagination.total;
        setAssAllData(modifiedData);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/hindipapers?filters[uploaded_by][$eq]=${username}`,

          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { meta } = await response.json();
        const modifiedData = meta.pagination.total;
        setHindiData(modifiedData);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [username]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/hindipapers`,

          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { meta } = await response.json();
        const modifiedData = meta.pagination.total;
        setHindiAllData(modifiedData);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/bengalipapers?filters[uploaded_by][$eq]=${username}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { meta } = await response.json();
        const modifiedData = meta.pagination.total;
        setBenData(modifiedData);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [username]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/bengalipapers`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { meta } = await response.json();
        const modifiedData = meta.pagination.total;
        setBenAllData(modifiedData);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  const total = engData + assData + benData + hindiData;

  return (
    <ApiDataContext.Provider
      value={{
        engData,
        assData,
        benData,
        hindiData,
        total,
        engAllData,
        assAllData,
        benAllData,
        hindiAllData,
        showAlert,
        setShowAlert,
        alertMsg,
        setAlertMsg,
        alertColour,
        setAlertColour,
        error,
        apiData,
        greetings,
      }}
    >
      {children}
    </ApiDataContext.Provider>
  );
};
