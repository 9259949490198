import React, { useContext } from "react";
import { ApiDataContext } from "../../context/ApiDataContext";

const PaperInfo = () => {
  const { engAllData, assAllData, benAllData, hindiAllData } =
    useContext(ApiDataContext);

  //function to calculate the total number of questions in percentage
  let total = assAllData + benAllData + hindiAllData + engAllData;
  let assPercentage = Math.round((assAllData / total) * 100);
  let engPercentage = Math.round((engAllData / total) * 100);
  let hindiPercentage = Math.round((hindiAllData / total) * 100);
  let benPercentage = Math.round((benAllData / total) * 100);

  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
      <h3 className="text-xl leading-none font-bold text-teal-700 mb-2">
        Total Overview
      </h3>
      <span className="text-base font-normal text-gray-500">
        Number Questions uploaded by all users.
      </span>
      <div className="block w-full overflow-x-auto mt-5">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th className="px-4 bg-gray-50 text-gray-800 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                Languages
              </th>
              <th className="px-4 bg-gray-50 text-gray-800 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                All Users
              </th>
              <th className="px-4 bg-gray-50 text-gray-800 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap min-w-140-px">
                Contribution %
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr className="text-indigo-600">
              <th className="border-t-0 px-4 align-middle text-sm font-semibold whitespace-nowrap p-4 text-left">
                Assamese
              </th>
              <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">
                {assAllData}
              </td>
              <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                <div className="flex items-center">
                  <span className="mr-2 text-xs font-medium">
                    {assPercentage}%
                  </span>
                  <div className="relative w-full">
                    <div className="w-full bg-gray-200 rounded-sm h-2">
                      <div
                        className="bg-indigo-600 h-2 rounded-sm"
                        style={{ width: `${assPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="text-orange-400">
              <th className="border-t-0 px-4 align-middle text-sm font-semibold whitespace-nowrap p-4 text-left">
                English
              </th>
              <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">
                {engAllData}
              </td>
              <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                <div className="flex items-center">
                  <span className="mr-2 text-xs font-medium">
                    {engPercentage}%
                  </span>
                  <div className="relative w-full">
                    <div className="w-full bg-gray-200 rounded-sm h-2">
                      <div
                        className="bg-orange-300 h-2 rounded-sm"
                        style={{ width: `${engPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="text-teal-400">
              <th className="border-t-0 px-4 align-middle text-sm font-semibold whitespace-nowrap p-4 text-left">
                Hindi
              </th>
              <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">
                {hindiAllData}
              </td>
              <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                <div className="flex items-center">
                  <span className="mr-2 text-xs font-medium">
                    {hindiPercentage}%
                  </span>
                  <div className="relative w-full">
                    <div className="w-full bg-gray-200 rounded-sm h-2">
                      <div
                        className="bg-teal-400 h-2 rounded-sm"
                        style={{ width: `${hindiPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="text-pink-600">
              <th className="border-t-0 px-4 align-middle text-sm font-semibold whitespace-nowrap p-4 text-left">
                Bengali
              </th>
              <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">
                {benAllData}
              </td>
              <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                <div className="flex items-center">
                  <span className="mr-2 text-xs font-medium">
                    {benPercentage}%
                  </span>
                  <div className="relative w-full">
                    <div className="w-full bg-gray-200 rounded-sm h-2">
                      <div
                        className="bg-pink-600 h-2 rounded-sm"
                        style={{ width: `${benPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaperInfo;
