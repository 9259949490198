import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import Search from "../assets/search.svg";
import { customAlphabet } from "nanoid";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Download = () => {
  const { lang } = useParams();
  const [paper, setPaper] = useState("");
  const [error, setError] = useState(null);
  const [apiData, setApiData] = useState([]);
  const { user } = useContext(AuthContext);

  const nanoid = customAlphabet("1234567890", 5);
  const paperId = nanoid();

  const navigate = useNavigate();

  let id = user ? user.id : "";

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (id >= 5 || id === "") {
      setError("Sorry ! You don't have access level to download papers");
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/${lang}?filters[paper_no][$eq]=${paper}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { data } = await response.json();
        const modifiedData = data.map((item) => {
          return {
            ...item,
          };
        });
        setApiData(modifiedData);
        setError(null);
        //console.log("download", modifiedData);
      } catch (err) {
        //console.log(err);
        setError(err.error.message);
      }
    }
  };

  const saveTxt = async (e) => {
    e.preventDefault();
    const date = dayjs().format("DD.MM.YYYY");

    const blob = new Blob([JSON.stringify(apiData, null, 2)], {
      type: "text/plain",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    link.download = `${lang}-${paperId}-${date}.txt`;
    link.click();
    link.remove();
    navigate("/dashboard");
  };

  useEffect(() => {
    window.scrollTo(0, "20px");
  }, []);

  return (
    <section className="text-gray-600 body-font h-screen">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={Search}
          />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h3 className="title-font sm:text-4xl text-3xl mb-10 font-medium text-gray-900 capitalize ">
            Keep a safe copy of your question paper
          </h3>
          {error && (
            <div
              className="bg-red-100 rounded-lg py-5 px-6 mb-4 text-base text-red-700   "
              role="alert"
            >
              <p className="text-md font-semibold">{`${error}.`}</p>
            </div>
          )}
          <div className="flex w-full md:justify-start justify-center items-end">
            <div className="relative mr-4 lg:w-full xl:w-1/2 w-2/4">
              <label
                htmlFor="hero-field"
                className="leading-7 text-md text-gray-600 mb-5"
              >
                Question Paper No.
              </label>
              <input
                value={paper}
                onChange={(e) => setPaper(e.target.value)}
                type="number"
                placeholder="Enter Paper Number"
                id="hero-field"
                name="hero-field"
                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-5"
              />
            </div>
            <button
              onClick={handleSubmit}
              className="inline-flex text-white bg-sky-500 border-0 py-2 px-6 focus:outline-none hover:bg-sky-600 rounded text-lg"
            >
              Search
            </button>
          </div>
          <p className="text-sm mt-2 text-gray-500 mb-8 w-full">
            Enter paper number to download
          </p>

          {apiData && apiData.length !== 0 && (
            <button
              onClick={saveTxt}
              className="inline-flex text-white bg-teal-500 border-0 py-2 px-6 focus:outline-none hover:bg-teal-600 rounded text-lg"
            >
              Download
            </button>
          )}
          {apiData && apiData.length === 0 && (
            <p className="text-sm mt-2 text-gray-500 mb-8 w-full">
              No paper found
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Download;
