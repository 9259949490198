import React, { useEffect, useState } from "react";

const Blog = () => {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NEWS_URL}`);
        const { data } = await response.json();
        const modifiedData = data.map((item) => {
          return {
            ...item,
          };
        });
        setApiData(modifiedData);
        setLoading(false);
        setError(null);
      } catch (err) {
        setError(err.message);
        //console.log(err);
      }
    };
    getData();
  }, []);

  //console.log("news", apiData);

  if (loading)
    return (
      <div className="grid min-h-screen place-content-center">
        <div className="flex items-center gap-2 text-gray-500">
          <span className="h-6 w-6 block rounded-full border-4 border-t-blue-300 animate-spin"></span>
          loading...
        </div>
      </div>
    );

  return (
    <section className="grid grid-flow-row-dense md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-5 mx-auto">
      {error && (
        <div>
          <p className="text-red-600 font-medium">{error.message}</p>
        </div>
      )}
      {apiData.map((item, i) => (
        <div
          className="relative flex flex-col m-5 bg-white z-30 p-3 mt-5"
          key={i}
        >
          <div className="relative  border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden transform transition-all hover:scale-110">
            <img
              src={item.imageUrl}
              alt={item.title}
              className="lg:h-48 md:h-36 w-full object-cover object-center"
              layout="responsive"
            />
          </div>
          <h2 className="my-3 font-semibold text-blue-500 text-xl">
            <a
              href={item.readMore}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline "
            >
              {item.title}
            </a>
          </h2>

          <div className="flex items-center mt-4">
            <div className="flex flex-col justify-between text-xs  mt-auto">
              <p className="text-gray-800 dark:text-white">{item.author}</p>
              <p className="text-gray-400 dark:text-gray-300">
                {item.date} {item.time}
              </p>
            </div>
          </div>
          <p className="text-md my-2 line-clamp-2 ">{item.content} </p>
          <div className="mt-4">
            <a
              href={item.readMoreUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="
                inline-flex
                items-center
                mt-auto
                font-semibold
                text-blue-600
                lg:mb-0
                hover:text-neutral-600
                hover:cursor-pointer
              "
              title="read more"
            >
              Read More »{" "}
            </a>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Blog;
