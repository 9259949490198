import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import CardAssamese from "./CardAssamese";
import CardBengali from "./CardBengali";
import CardEnglish from "./CardEnglish";
import CardHindi from "./CardHindi";
import LastQuestions from "./LastQuestions";
import PaperInfo from "./PaperInfo";

import Users from "./Users";

const CardMain = () => {
  const { user } = useContext(AuthContext);
  return (
    <section className="min-h-screen bg-gray-50">
      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
        <div className="mb-4 flex items-center justify-between">
          <div>
            <h3 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-purple-500 mb-2">
              Latest Number of Questions
            </h3>
            <span className="text-base font-normal text-gray-500">
              List of Questions added by{" "}
              <span className=" capitalize text-blue-500 font-semibold ">
                {" "}
                {user.username}.
              </span>
            </span>
          </div>
        </div>
        <div className="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
          <CardAssamese />
          <CardHindi />
          <CardEnglish />
          <CardBengali />
        </div>
        <div className=" mt-10 " />

        <LastQuestions />
        <div className=" mt-10 " />

        <PaperInfo />

        <Users />
      </div>
    </section>
  );
};

export default CardMain;
