import React from "react";
import { BellIcon } from "@heroicons/react/outline";

const Success = () => {
  return (
    <div className="">
      <div className="w-full px-4 py-4 overflow-x-hidden text-center whitespace-no-wrap rounded-md mt-10 min-h-screen">
        <div className="inline-flex w-full max-w-sm ml-3 overflow-hidden bg-slate-100 rounded-lg shadow-sm">
          <div className="flex items-center justify-center w-12 bg-green-500">
            <BellIcon className="w-6 h-6 text-white" />
          </div>
          <div className="px-3 py-2 text-left">
            <span className="font-semibold text-green-500">Success</span>
            <p className="mb-1 text-sm leading-none text-gray-500">
              Successfully Submitted!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
