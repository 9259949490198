import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { ApiDataContext } from "../../context/ApiDataContext";
const CardBengali = () => {
  const { benData } = useContext(ApiDataContext);

  const total = benData;
  return (
    <div className="bg-slate-50 shadow-md rounded-lg p-4 sm:p-6 xl:p-8 ">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
            {total}
          </span>
          <h3 className="text-base font-normal text-gray-500">
            Total Number Questions
          </h3>
          <div className="flex-shrink-0">
            <Link
              to="/form/bengalipapers"
              className="text-sm font-medium text-blue-600 hover:bg-gray-100 rounded-lg p-2 underline"
            >
              New Entry
            </Link>
            <Link
              to="/edit/bengalipapers"
              className="text-sm font-medium text-blue-600 hover:bg-gray-100 rounded-lg p-2 underline"
            >
              Update Entry
            </Link>
          </div>
        </div>
        <div className="ml-5 w-0 flex items-center justify-end flex-1 text-pink-600 text-base font-bold">
          Bengali
        </div>
      </div>
    </div>
  );
};

export default CardBengali;
