import React, { useContext } from "react";
import { ApiDataProvider } from "../context/ApiDataContext";
import { AuthContext } from "../context/AuthContext";

import Alert from "../components/Alert";
import CardMain from "../components/cards/CardMain";

const Dashboard = () => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return;
  } else {
    return (
      <>
        <ApiDataProvider>
          <Alert />
          <CardMain />
        </ApiDataProvider>
      </>
    );
  }
};

export default Dashboard;
