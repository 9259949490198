import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiDataProvider } from "../context/ApiDataContext";
import { AuthContext } from "../context/AuthContext";
import DataEntry from "../components/DataEntry";
import Login from "./Login";

const Form = () => {
  const { user } = useContext(AuthContext);

  const { lang } = useParams();

  const mapData = [
    { id: 1, name: "question-1" },
    { id: 2, name: "question-2" },
    { id: 3, name: "question-3" },
    { id: 4, name: "question-4" },
    { id: 5, name: "question-5" },
    { id: 6, name: "question-6" },
    { id: 7, name: "question-7" },
    { id: 8, name: "question-8" },
    { id: 9, name: "question-9" },
    { id: 10, name: "question-10" },
  ];
  const [num, setNum] = useState(1);

  const value = mapData.filter((item) => num <= item.id);

  //function to handle increment
  const handleNextQuestion = () => {
    setNum(num + 1);
  };

  if (!user) {
    return (
      <>
        <Login />
      </>
    );
  } else {
    return (
      <>
        <ApiDataProvider>
          <section className="pb-5">
            {value.map((map, i) => (
              <DataEntry
                lang={lang}
                key={map.id}
                map={map.name}
                id={map.id}
                index={i}
                handleSlice={handleNextQuestion}
              />
            ))}
          </section>
        </ApiDataProvider>
      </>
    );
  }
};
export default Form;
