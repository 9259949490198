import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ApiDataContext } from "../context/ApiDataContext";
import Alert from "./Alert";

const parseJSON = (resp) => (resp.json ? resp.json() : resp);
// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  return parseJSON(resp).then((resp) => {
    throw resp;
  });
};

const DataEntryEdit = ({
  id,
  handleSlice,
  lang,
  correct_ans,
  options,
  paperNum,
  questions,
}) => {
  const {
    setShowAlert,

    setAlertMsg,

    setAlertColour,
  } = useContext(ApiDataContext);
  const [error, setError] = useState(null);
  const [optionA, setOptionA] = useState(options[0]);
  const [optionB, setOptionB] = useState(options[1]);
  const [optionC, setOptionC] = useState(options[2]);
  const [optionD, setOptionD] = useState(options[3]);

  const [paperNo, setPaperNo] = useState(paperNum);
  const [correct_answer, setCorrect_answer] = useState(correct_ans);
  const [question, setQuestion] = useState(questions);

  let optionArray = [optionA, optionB, optionC, optionD];

  const navigate = useNavigate();

  const questionModel = {
    paper_no: paperNo,
    correct_answer: correct_answer,
    question: question,

    options: optionArray,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/${lang}/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ data: questionModel }),
        }
      )
        .then(checkStatus)
        .then(parseJSON);
      setError(null);
      setShowAlert(true);
      setAlertMsg(`Question No. ${response.data.id} Successfully Updated`);
      setAlertColour("teal");
      handleSlice();

      //console.log("response", response);
    } catch (err) {
      setError(err.error.message);
      setShowAlert(true);
      setAlertMsg(err.error.message);
      setAlertColour("red");
    }
  };

  return (
    <>
      <Alert className=" mt-5 mb-5" />
      <main className="bg-slate-50  ">
        <section className=" mx-auto max-w-6xl bg-gray-100 pt-5">
          <div className="p-5 ">
            <span className="bg-blue-100 text-blue-800 text-md font-semibold mr-2 px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
              Question No.
              <span>{id}</span>
            </span>
            <div className="mt-8 p-4 ">
              <form>
                <div className="flex flex-col md:flex-row mb-6 ">
                  <div className="w-full mx-2 flex-1  ">
                    <label
                      htmlFor="Question"
                      className="form-label font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                    >
                      Paper No.
                      <span className="text-lg text-rose-500">*</span>
                    </label>
                    <div className="flex ">
                      <input
                        value={paperNo}
                        onChange={(e) => setPaperNo(e.target.value)}
                        type="number"
                        placeholder="Paper No."
                        className="form-control block w-1/3 px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="form-group mb-6 w-full mx-2 ">
                  <label
                    htmlFor="Question"
                    className="form-label font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                  >
                    Question
                    <span className="text-lg text-rose-500">*</span>
                  </label>
                  <textarea
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    type="text"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Question"
                    rows="3"
                  />
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="w-full mx-2 flex-1 ">
                    <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
                      {" "}
                      Option A<span className="text-lg text-rose-500">*</span>
                    </div>
                    <div className="flex ">
                      <textarea
                        value={optionA}
                        onChange={(e) => setOptionA(e.target.value)}
                        type="text"
                        placeholder="Option A"
                        className="form-control block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      />{" "}
                    </div>
                  </div>
                  <div className="w-full mx-2 flex-1 ">
                    <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
                      {" "}
                      Option B<span className="text-lg text-rose-500">*</span>
                    </div>
                    <div className=" flex ">
                      <textarea
                        value={optionB}
                        onChange={(e) => setOptionB(e.target.value)}
                        type="text"
                        placeholder="Option B"
                        className="form-control block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row">
                  <div className="w-full mx-2 flex-1 ">
                    <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
                      {" "}
                      Option C<span className="text-lg text-rose-500">*</span>
                    </div>
                    <div className="flex ">
                      <textarea
                        value={optionC}
                        onChange={(e) => setOptionC(e.target.value)}
                        type="text"
                        placeholder="Option C"
                        className="form-control block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      />{" "}
                    </div>
                  </div>
                  <div className="w-full mx-2 flex-1 ">
                    <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
                      {" "}
                      Option D<span className="text-lg text-rose-500">*</span>
                    </div>
                    <div className=" flex ">
                      <textarea
                        value={optionD}
                        onChange={(e) => setOptionD(e.target.value)}
                        type="text"
                        placeholder="Option D"
                        className="form-control block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="form-group mb-6 w-full mx-2 mt-10 ">
                  <label
                    htmlFor="Correct Answer"
                    className="form-label font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                  >
                    Correct Answer
                    <span className="text-lg text-rose-500">*</span>
                  </label>
                  <textarea
                    value={correct_answer}
                    onChange={(e) => setCorrect_answer(e.target.value)}
                    type="text"
                    className="form-control block w-full px-4 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Correct Answer"
                  />
                </div>
                <div className="flex items-center  pb-6 mt-12 justify-between ">
                  <p className="mb-0 mr-2 text-red-600 font-semibold italic text-lg">
                    Verify before updating
                  </p>
                  <button
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    type="button"
                    className="inline-block px-6 mr-5 py-2 border-2 border-rose-600 text-rose-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    Back
                  </button>
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Update
                  </button>
                </div>
              </form>
              {error && (
                <div
                  className="bg-red-100 rounded-lg py-5 px-6 mb-4 text-base text-red-700 animate-bounce  "
                  role="alert"
                >
                  <p className="text-md font-semibold">{`There is a problem fetching the server - ${error}.`}</p>
                </div>
              )}
            </div>
          </div>
          <div className="border-solid border-b-2 border-stone-500" />
        </section>
      </main>
    </>
  );
};

export default DataEntryEdit;
