import React, { useContext, useEffect } from "react";
import { ApiDataContext } from "../context/ApiDataContext";
import { AuthContext } from "../context/AuthContext";
import { BellIcon } from "@heroicons/react/outline";

const Alert = () => {
  const { user } = useContext(AuthContext);
  const {
    showAlert,
    setShowAlert,
    alertMsg,
    greetings,

    alertColour,
  } = useContext(ApiDataContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAlert(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [showAlert, setShowAlert]);

  return (
    <>
      {showAlert ? (
        <div
          className={
            "text-white px-6 py-4 border-0 rounded relative mb-4 bg-" +
            alertColour +
            "-500"
          }
        >
          <span className="text-xl inline-block mr-5 align-middle">
            <BellIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
          <span className="inline-block align-middle mr-8">
            <b className="capitalize">
              {greetings} ! {user.username}
            </b>{" "}
            {alertMsg}
          </span>
          <button
            className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            onClick={() => setShowAlert(false)}
          >
            <span>×</span>
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Alert;
