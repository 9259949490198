import React, { useEffect } from "react";
import { nanoid } from "nanoid";
import { useForm } from "react-hook-form";

import Success from "../components/Success";

const ContactUs = () => {
  const captcha = nanoid(4);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isError, setIsError] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState("");

  const onSubmit = async (data) => {
    const captchaInput = document.getElementById("captcha").value;
    if (captchaInput === captcha) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/feedbacks`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ data: data }),
        }
      );

      const json = await response.json();
      if (json.success) {
        setIsSubmitted(true);
        setIsSuccess(json.message);
      } else {
        setIsError("Something went wrong");
      }
      setIsSuccess("Successfully Submitted");

      setIsError("Successfully Submitted");

      setIsSubmitted(false);
    } else {
      setIsError("Invalid Captcha");
    }
  };

  useEffect(() => {
    window.scrollTo(0, "20px");
  }, []);

  return (
    <>
      {isSuccess ? (
        <Success />
      ) : (
        <section className="text-gray-600 body-font relative">
          <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
            <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
              <iframe
                width="100%"
                height="100%"
                className="absolute inset-0"
                frameBorder="0"
                title="map"
                marginHeight="0"
                marginWidth="0"
                scrolling="no"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Dudhnoi+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
              ></iframe>
              <div className="bg-white relative flex flex-wrap py-6 px-6 rounded shadow-md ">
                <div className="lg:w-1/2 px-6">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    ADDRESS
                  </h2>
                  <p className="mt-1">LicWay</p>
                  <p className="mt-1">Latu Solutions</p>
                </div>
                <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    EMAIL
                  </h2>
                  <span className="text-indigo-500 leading-relaxed">
                    <a
                      href="mailto:
                "
                    >
                      support@licway.com
                    </a>
                  </span>
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                    PHONE
                  </h2>
                  <p className="leading-relaxed">8822882288</p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
              <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
                Feedback
              </h2>
              <p className="leading-relaxed mb-5 text-gray-600">
                Post your feedback here.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="relative mb-4">
                  <label
                    htmlFor="name"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Name
                  </label>
                  <input
                    {...register("name", { required: true })}
                    type="text"
                    placeholder="Name"
                    id="name"
                    name="name"
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  {errors.name && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div className="relative mb-4">
                  <label
                    htmlFor="email"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    placeholder="Email"
                    id="email"
                    name="email"
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  {errors.email && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div className="relative mb-4">
                  <label
                    htmlFor="message"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Message
                  </label>
                  <textarea
                    {...register("message", { required: true })}
                    id="message"
                    placeholder="Your message"
                    name="message"
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                  {errors.message && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div className="relative mb-4">
                  <label
                    htmlFor="captcha"
                    className="leading-7 text-sm text-gray-600 mb-4"
                  >
                    Captcha{" "}
                    <span className="bg-slate-200 px-2 py-2 font-bold tracking-[0.5em] text-lg">
                      {captcha}
                    </span>
                  </label>
                  <input
                    type="text"
                    id="captcha"
                    name="captcha"
                    placeholder="Enter Captcha"
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-4"
                  />
                </div>
                <>
                  {isSubmitted ? (
                    <button
                      disabled={isSubmitted}
                      className="text-white bg-gray-500 border-0 py-2 px-6 focus:outline-none hover:bg-gray-600 rounded text-lg"
                      type="submit"
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      className="text-white bg-teal-500 border-0 py-2 px-6 focus:outline-none hover:bg-teal-600 rounded text-lg"
                      type="submit"
                    >
                      Submit
                    </button>
                  )}
                </>
              </form>
              {isError && <p className="text-red-500 mt-5">{isError}</p>}
              <p className="text-xs text-gray-500 mt-3">
                We will contact you soon.
              </p>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ContactUs;
