import React, { useContext } from "react";
import Avatar from "../../assets/avatar.svg";
import { ApiDataContext } from "../../context/ApiDataContext";
import dayjs from "dayjs";

const LastQuestions = () => {
  const { total, error, apiData } = useContext(ApiDataContext);

  let rewards = [
    "Pen",
    "File Cover",
    "Bag",
    "Sunglass",
    "T-Shirt",
    "Wallet",
    "Cap",
    "Jeans",
    "Watch",
    "headphone",
    "Shoes",
    "Bluetooth Speaker",
  ];

  // function for rewards with conditions
  if (total <= 50) {
    rewards = rewards.slice(0, 1);
  } else if (total > 50 && total <= 100) {
    rewards = rewards.slice(1, 2);
  } else if (total > 100 && total <= 150) {
    rewards = rewards.slice(2, 3);
  } else if (total > 150 && total <= 200) {
    rewards = rewards.slice(3, 4);
  } else if (total > 200 && total <= 250) {
    rewards = rewards.slice(4, 5);
  } else if (total > 250 && total <= 300) {
    rewards = rewards.slice(5, 6);
  } else if (total > 300 && total <= 350) {
    rewards = rewards.slice(6, 7);
  } else if (total > 350 && total <= 400) {
    rewards = rewards.slice(7, 8);
  } else if (total > 400 && total <= 450) {
    rewards = rewards.slice(8, 9);
  } else if (total > 450 && total <= 500) {
    rewards = rewards.slice(9, 10);
  } else if (total > 500 && total <= 550) {
    rewards = rewards.slice(10, 11);
  } else if (total > 550 && total <= 600) {
    rewards = rewards.slice(11, 12);
  } else if (total > 600 && total <= 650) {
    rewards = rewards.slice(12, 13);
  } else if (total > 650 && total <= 700) {
    rewards = rewards.slice(13, 14);
  } else if (total > 700 && total <= 750) {
    rewards = rewards.slice(14, 15);
  } else if (total > 750 && total <= 800) {
    rewards = rewards.slice(15, 16);
  } else if (total > 800 && total <= 850) {
    rewards = rewards.slice(16, 17);
  } else if (total > 850 && total <= 900) {
    rewards = rewards.slice(17, 18);
  } else if (total > 900 && total <= 950) {
    rewards = rewards.slice(18, 19);
  } else if (total > 950 && total <= 1000) {
    rewards = rewards.slice(19, 20);
  }

  // function for no rewards to users

  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 mt-5 ">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <h3 className="text-xl font-bold text-cyan-600 mb-2">User Profile</h3>
          <span> Date - {dayjs().format(" DD/MMM/YYYY")}</span>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="overflow-x-auto rounded-lg">
          <div className="align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      className="p-4 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider"
                    >
                      Access Level
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider"
                    >
                      Total Uploaded
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider"
                    >
                      Rewards
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {error && <div className="text-red-500">{error.message}</div>}
                  <tr className="bg-gray-50">
                    <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900 rounded-lg rounded-left">
                      <span className="flex items-center space-x-4">
                        <span className="flex-shrink-0">
                          <img
                            className="h-8 w-8 rounded-full"
                            src={apiData.img || Avatar}
                            alt="user"
                          />
                        </span>
                        <span className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate capitalize">
                            {apiData && apiData.username}
                          </p>
                        </span>
                      </span>
                    </td>
                    <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                      <span className="font-semibold text-center">
                        {apiData && apiData.id}
                      </span>
                    </td>
                    <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      {total}
                    </td>
                    <td className="p-4 whitespace-nowrap text-sm font-semibold  animate-pulse text-teal-600 capitalize">
                      <span>{rewards}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastQuestions;
