import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "./pages/Login";
import Form from "./pages/Form";
import Home from "./pages/Home";
import Error from "./pages/Error";
import NavBar from "./components/NavBar";
import Dashboard from "./pages/Dashboard";
import Footer from "./components/Footer";
import Edit from "./pages/Edit";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Download from "./pages/Download";
import ContactUs from "./pages/ContactUs";
import Blog from "./pages/Blog";
import Projects from "./pages/Projects";

function App() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/news" element={<Blog />} />
          <Route path="/projects" element={<Projects />} />
          <Route
            path="/login"
            element={user ? <Navigate to="/dashboard" replace /> : <Login />}
          />
          <Route
            path="/dashboard"
            element={!user ? <Navigate to="/login" replace /> : <Dashboard />}
          />
          <Route path="/form/:lang" element={<Form />} />
          <Route path="/edit/:lang" element={<Edit />} />
          <Route
            path="/download/:lang"
            element={!user ? <Navigate to="/login" replace /> : <Download />}
          />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
