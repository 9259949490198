import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiDataProvider } from "../context/ApiDataContext";
import { AuthContext } from "../context/AuthContext";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

import DataEntryEdit from "../components/DataEntryEdit";
import Login from "./Login";
import useSWR from "swr";

const Edit = () => {
  const [page, setPage] = useState(1);
  const { user } = useContext(AuthContext);

  const { lang } = useParams();

  const [num, setNum] = useState(1);

  //function to handle increment
  const handleNextQuestion = () => {
    setNum(num + 1);
  };

  // use swr

  const fetcher = (...arr) => fetch(...arr).then((res) => res.json());
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/v1/${lang}?pagination[page]=${page}&pagination[pageSize]=10&filters[uploaded_by][$eq]=${user.username}`,
    fetcher
  );

  if (!user) {
    return (
      <>
        <Login />
      </>
    );
  } else {
    return (
      <ApiDataProvider>
        <>
          {error && (
            <div
              className="bg-red-100 rounded-lg py-5 px-6 mb-4 text-base text-red-700 animate-bounce  "
              role="alert"
            >
              <p className="text-md font-semibold">{`There is a problem fetching the server - ${error}.`}</p>
            </div>
          )}

          {data &&
            data.data.map((q, i) => (
              <DataEntryEdit
                key={q.id}
                id={q.id}
                index={i}
                paperNum={q.paper_no}
                questions={q.question}
                correct_ans={q.correct_answer}
                options={q.options}
                lang={lang}
                handleSlice={handleNextQuestion}
              />
            ))}
          <div className="flex items-start justify-between  px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
            <div className="flex justify-between flex-1 sm:hidden">
              <button className="relative inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700">
                Previous
              </button>
              <button className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700">
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm leading-5 text-gray-700">
                  Showing page{" "}
                  <span className="font-medium">
                    {data && data.meta.pagination.page}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium">
                    {" "}
                    {data && data.meta.pagination.pageCount}.
                  </span>{" "}
                  Total{" "}
                  <span className="font-medium">
                    {data && data.meta.pagination.total}
                  </span>{" "}
                  results found.
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex shadow-sm">
                  <button
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                    className="relative inline-flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-l-md hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500"
                    aria-label="Previous"
                  >
                    <ChevronLeftIcon
                      className={`h-5 w-5 ${
                        page === 1 ? "text-gray-400" : "text-sky-500"
                      }`}
                    />
                  </button>
                  <button
                    onClick={() =>
                      setPage(data && data.meta.pagination.page - 1)
                    }
                    disabled={page === 1}
                    className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700"
                  >
                    {data && data.meta.pagination.page}
                  </button>
                  <button
                    onClick={() =>
                      setPage(data && data.meta.pagination.page + 1)
                    }
                    disabled={
                      data &&
                      data.meta.pagination.page === data &&
                      data.meta.pagination.pageCount
                    }
                    className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700"
                  >
                    {data && data.meta.pagination.page + 1}
                  </button>

                  <span className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium leading-5 text-gray-700 bg-white border border-gray-300">
                    ...
                  </span>
                  <button
                    onClick={() =>
                      setPage(data && data.meta.pagination.pageCount - 1)
                    }
                    className="relative items-center hidden px-4 py-2 -ml-px text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 md:inline-flex hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700"
                  >
                    {data &&
                      data.meta.pagination.pageCount +
                        data.meta.pagination.page -
                        data.meta.pagination.page}
                  </button>

                  <button
                    onClick={() => setPage(page + 1)}
                    disabled={
                      data &&
                      data.meta.pagination.page === data &&
                      data.meta.pagination.pageCount
                    }
                    className="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-r-md hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500"
                    aria-label="Next"
                  >
                    <ChevronRightIcon
                      className={`h-5 w-5 ${
                        data &&
                        data.meta.pagination.page === data &&
                        data.meta.pagination.pageCount
                          ? "text-gray-400"
                          : "text-sky-500"
                      }`}
                    />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </>
      </ApiDataProvider>
    );
  }
};
export default Edit;
