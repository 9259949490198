import HeroCard from "../components/cards/HeroCard";
import Feature from "../components/cards/Feature";
import Team from "../components/cards/Team";

const Home = () => {
  return (
    <>
      <HeroCard />
      <Feature />
      <Team />
    </>
  );
};

export default Home;
