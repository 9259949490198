import React from "react";
import Logo from "../assets/dashboard-logo.svg";

const Footer = () => {
  return (
    <footer>
      <div className="bg-gray-200 z-50">
        <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
          <div className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img
              src={Logo}
              alt="logo"
              className="w-10 h-10  p-2 bg-white rounded-full"
            />

            <span className="ml-3 text-xl">Latu Solutions</span>
          </div>

          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            {" "}
            <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
              &copy; {new Date().getFullYear()}
              <a
                href="/"
                rel="noopener noreferrer"
                className="text-gray-600 ml-1"
                target="_blank"
              >
                Latu Solutions
              </a>
            </p>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
