import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ApiDataContext } from "../../context/ApiDataContext";

const Users = () => {
  const { engAllData, assAllData, benAllData, hindiAllData } =
    useContext(ApiDataContext);

  let paperNum = assAllData;
  let paperNum2 = benAllData;
  let paperNum3 = hindiAllData;
  let paperNum4 = engAllData;
  let paper = ["Paper No. 1,", "Paper No. 2,", "Paper No. 3,"];
  let paper2 = ["Paper No. 1,", "Paper No. 2,", "Paper No. 3,"];
  let paper3 = ["Paper No. 1,", "Paper No. 2,", "Paper No. 3,"];
  let paper4 = ["Paper No. 1,", "Paper No. 2,", "Paper No. 3,"];

  if (paperNum <= 49) {
    paper = paper.slice(0, 1);
  } else if (paperNum > 49 && paperNum <= 100) {
    paper = paper.slice(1, 2);
  } else if (paperNum > 99 && paperNum <= 150) {
    paper = paper.slice(2, 3);
  } else if (paperNum > 49 && paperNum <= 200) {
  }
  if (paperNum2 <= 49) {
    paper2 = paper2.slice(0, 1);
  } else if (paperNum2 > 49 && paperNum2 <= 100) {
    paper2 = paper2.slice(1, 2);
  } else if (paperNum2 > 99 && paperNum2 <= 150) {
    paper2 = paper2.slice(2, 3);
  } else if (paperNum2 > 149 && paperNum2 <= 200) {
  }
  if (paperNum3 <= 49) {
    paper3 = paper3.slice(0, 1);
  } else if (paperNum3 > 50 && paperNum3 <= 100) {
    paper3 = paper3.slice(1, 2);
  } else if (paperNum3 > 99 && paperNum3 <= 150) {
    paper3 = paper3.slice(2, 3);
  } else if (paperNum3 > 149 && paperNum3 <= 200) {
  }
  if (paperNum4 <= 49) {
    paper4 = paper4.slice(0, 1);
  } else if (paperNum4 > 49 && paperNum4 <= 100) {
    paper4 = paper4.slice(1, 2);
  } else if (paperNum4 > 99 && paperNum4 <= 150) {
    paper4 = paper4.slice(2, 3);
  } else if (paperNum4 > 149 && paperNum4 <= 200) {
  }

  return (
    <>
      <div className="bg-white shadow rounded-lg mb-4 p-4 sm:p-6 h-full mt-5">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-xl font-bold leading-none text-sky-700">
            Download Question Papers
          </h3>
        </div>
        <div className="flow-root">
          <ul className="divide-y divide-gray-200">
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-indigo-600 truncate">
                    Assamese
                  </p>
                  <p className=" truncate text-xs text-gray-800 font-medium">
                    Current Paper-
                    <span className="font-semibold text-indigo-600 animate-pulse">
                      {paper}
                    </span>
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-normal hover:text-teal-600 text-teal-500 hover:underline">
                  <Link to="/download/assamesepapers">Click</Link>
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-orange-400 truncate">
                    English
                  </p>
                  <p className=" truncate text-xs text-gray-800 font-medium">
                    Current Paper-
                    <span className=" font-semibold text-orange-400 animate-pulse">
                      {paper4}
                    </span>
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-normal hover:text-teal-600 text-teal-500 hover:underline">
                  <Link to="/download/englishpapers">Click</Link>
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-teal-400 truncate">
                    Hindi
                  </p>
                  <p className=" truncate text-xs text-gray-800 font-medium">
                    Current Paper-
                    <span className="font-semibold text-teal-400 animate-pulse">
                      {paper3}
                    </span>
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-normal hover:text-teal-600 text-teal-500 hover:underline">
                  <Link to="/download/hindipapers">Click</Link>
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-pink-600 truncate">
                    Bengali
                  </p>
                  <p className=" truncate text-xs text-gray-800 font-medium">
                    Current Paper-
                    <span className="font-semibold text-pink-600 animate-pulse">
                      {paper2}
                    </span>
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-normal hover:text-teal-600 text-teal-500 hover:underline">
                  <Link to="/download/bengalipapers">Click</Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Users;
