import React from "react";
import Logo from "../../assets/dashboard-logo.svg";
import Hero from "../../assets/hero.svg";
import { Link } from "react-router-dom";

const HeroCard = () => {
  return (
    <div className="relative items-center justify-center w-full overflow-x-hidden lg:pt-40 lg:pb-40 xl:pt-40 xl:pb-64">
      <div className="container flex flex-col items-center justify-between h-full max-w-6xl px-8 mx-auto -mt-32 lg:flex-row xl:px-0">
        <div className="z-30 flex flex-col items-center w-full max-w-xl pt-48 text-center lg:items-start lg:w-1/2 lg:pt-20 xl:pt-40 lg:text-left">
          <h1 className="relative mb-4 text-3xl font-black leading-tight text-gray-900 sm:text-6xl xl:mb-8">
            Build the Next Great Thing
          </h1>
          <p className="pr-0 mb-8 text-base text-gray-600 sm:text-lg xl:text-xl lg:pr-20">
            Welcome to the Latux Admin Panel
          </p>
          <div className="flex">
            <Link to="/login">
              <button className="relative self-start inline-block w-auto px-8 py-3 mx-auto mt-0 text-base font-bold text-white bg-sky-500 border-t border-gray-200 rounded-md shadow-xl sm:mt-1 fold-bold lg:mx-0">
                Signup
              </button>
            </Link>
            <div className="ml-5">
              {" "}
              <Link to="/contact">
                <button className=" relative self-start inline-block w-auto px-6 py-3 mx-auto mt-0 text-base font-bold text-gray-500 bg-gray-200   rounded-md sm:mt-1 fold-bold lg:mx-0">
                  Learn More
                </button>
              </Link>
            </div>
          </div>

          <div className="flex-col hidden mt-12 sm:flex lg:mt-24">
            <p className="mb-4 text-sm font-medium tracking-widest text-gray-500 uppercase">
              Integrates With
            </p>
            <div className="flex">
              <img
                className="h-8 mr-4 text-gray-500 duration-150 cursor-pointer fill-current transition-color hover:text-gray-600"
                src={Logo}
                alt="logo"
              />
              <p className=" text-sm font-normal tracking-widest text-gray-500 uppercase">
                Latux API
              </p>
            </div>
          </div>
        </div>
        <div className="relative z-50 flex flex-col items-end justify-center w-full h-full lg:w-1/2 ms:pl-10">
          <div className="container relative left-0 w-full max-w-4xl lg:absolute xl:max-w-6xl lg:w-screen">
            <img
              src={Hero}
              className="w-full h-auto mt-20 mb-20 ml-0 lg:mt-24 xl:mt-40 lg:mb-0 lg:h-full lg:-ml-12"
              alt="hero"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCard;
